// const hostApi =   process.env.NODE_ENV === "development" ? "https://itau.space": "https://itau.space"
const hostApi =   process.env.NODE_ENV === "development" ? "https://www.pessasys.com/v1": "https://pessasys.com/v1"
 
const portApi =   process.env.NODE_ENV === "development"? "" : "";  
const baseURLApi = `${hostApi}${portApi ? `:${portApi}` :  ``}/api`;
// const redirectUrl = process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://itau.space/pessa";
const redirectUrl = process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://pessasys.com";

export default {

  hostApi,
  portApi,
  baseURLApi,
  redirectUrl,
  remote: baseURLApi,
  isBackend: process.env.REACT_APP_BACKEND,
  auth: {
    email: 'admin@flatlogic.com',
    password: 'password',
  },
  app: {
    colors: {
      dark: '#002B49',
      light: '#FFFFFF',
      sea: '#004472',
      sky: '#E9EBEF',
      wave: '#D1E7F6',
      rain: '#CCDDE9',
      middle: '#D7DFE6',
      black: '#13191D',
      salat: '#21AE8C',
    },
  },
};
